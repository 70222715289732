import {Component, Input, OnInit, OnChanges} from '@angular/core';
import * as Highcharts from "highcharts/highstock";
import {high_colors} from "../general-chart/general-chart.component";

@Component({
    selector: 'app-area-chart',
    templateUrl: './area-chart.component.html',
    styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit {
    @Input() optimizationData: any;
    @Input() minVolatility: any;
    @Input() maxVolatility: any;
    @Input() volatilityLine: any;
    @Input() volatilityLineText: string;

    loaded = false;
    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = 'chart';
    chartOptions: Highcharts.Options = {};
    chartData: any[] = [];
    lineLabels = {
        efficient_portfolio_max_sharpe_ratio: 'Max Sharpe PF',
        efficient_portfolio_given_mu: 'Target Return PF',
        efficient_portfolio_given_sigma: 'Target Risk PF',
        baseline_portfolio: 'Baseline PF',
        efficient_portfolio_max_mu: 'Maximum Return',
        minimum_variance_portfolio: 'Minimum Risk'
    }

    ngOnInit(): void {
        this.initChart()
    }
    ngOnChanges(): void {
        this.initChart()
    }

    initChart() {
        this.loaded = false;
        this.chartData = [];
        var index = 0;
        const zip = (a, b) => a.map((k, i) => [k, b[i]]);
        this.optimizationData.map((d: any) => {
            this.chartData.push({
                name: d.company_name,
                data: zip( d.vol,d.id_w),
                type: 'area'
            });
            index += 1;
        })
        this.chartOptions = {
            chart: {
                height: '750px',
                type: 'area',
                backgroundColor: null
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            // colors: high_colors,
            colors: (() => {
                const baseColor = '#424CCD';
                const colors = [];
                colors.push(Highcharts.color(baseColor).get());
                for (let i = 1; i <= this.chartData.length; i++) {
                    colors.push(Highcharts.color(baseColor).brighten((0.5/this.chartData.length) * i).get());
                }
                return colors;
            })(),
            tooltip: {
         //       valueSuffix: '%',
                formatter: function () {
                    this.points.sort((a, b) => {return (b.y) - (a.y)});
                    return this.points.reduce(function (s, point) {
                            if(point.y > 0){
                                return s + '<br/><span style="color:'+point.color+'">\u25CF</span> ' + point.series.name + ': ' +
                                point.y + '%';
                            }else{
                                return s;
                            }
                            
                        }, '<b>Std.Dev:' + this.x + '%</b>');
                    },

                 
                shared: true
            },
            plotOptions: {
                area: {
                    pointStart: this.minVolatility,
                   // pointInterval: 0.1,
                    relativeXValue: false,
                    stacking: 'percent',
                    marker: {
                        enabled: false
                    }
                }
            },
            yAxis: {
                
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: 'Allocation'
                }
            },
            xAxis: {
                type:'linear',

            labels: {
                    format: '{value}%'
                },
            title: {
                    text: 'Standard Deviation'
                },
            plotLines: [{
                    color: 'darkgray',

                    width: 2,
                    value: this.volatilityLine,
                    zIndex: 5,
                //    text : this.lineLabels[this.volatilityLineText],
                    label: {
                      //  text: ,
                        text: "<div style='background-color: #0c0c68; color: white'> " + this.lineLabels[this.volatilityLineText] + "</div>",
                        useHTML: true,
                        // formatter: function () {
                        //     return "<div style='background-color: blue; color: white'> " + " Max Sharpe PF" + "</div>"
                        //  },
                        style: {
                            color: 'white',
                            fontWeight: 'bold',
                        },
                        align: "center",
                        verticalAlign: "middle",
                        rotation: 360
                    }
                }]
                

            },
            series: this.chartData
        };
        setTimeout(() => this.loaded = true, 10);
    }
}
