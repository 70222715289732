import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-heatmap-table',
  templateUrl: './heatmap-table.component.html',
  styleUrls: ['./heatmap-table.component.scss']
})
export class HeatmapTableComponent implements OnChanges, OnInit {
  @Input() dataRaw: any = null;
  @Input() allowedTickers: any[] = [];
  @Input() title: string;

  tableData: any[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowedTickers) {
      this.initData();
    }
  }

  initData() {
    if (this.allowedTickers && this.allowedTickers.length) {
      const filteredData = [];

      let iterCol = [];
      for (const rowData of this.dataRaw) {
        iterCol = [];
        // iter through each row, if Var2 is in tickers check columns
        if (this.allowedTickers.includes(rowData[0].Var2Full)) {
          for (const data of rowData) {
            if (this.allowedTickers.includes(data.Var1Full)) {
              iterCol.push(data);
            }
          }
          filteredData.push(iterCol);
        }
      }
      this.tableData = filteredData;
    } else {
      this.tableData = this.dataRaw;
    }

  }

  getTooltipText(v1,v2){
    return `x: ${v1}  
          \n
          y: ${v2}`;
  }

  ngOnInit(): void {
    this.dataRaw
        .map(row => {
          row.map(column => {
            column.fullName1 = column.Var1Full ?? column.companyName1;
            column.fullName2 = column.Var2Full ?? column.companyName2;

            return column;
          })
          return row;
        })
    this.initData();
  }

}
