import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GeneralChartComponent} from "../general-chart/general-chart.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {PortfolioService} from "../../pages/portfolio/portfolio.service";

@Component({
  selector: 'app-efficient-frontier-chart',
  templateUrl: './efficient-frontier-chart.component.html',
  styleUrls: ['./efficient-frontier-chart.component.scss']
})
export class EfficientFrontierChartComponent extends GeneralChartComponent{

  @Input() optimWeights: any[] = [];
  @Input() isPrint = false;
  @Input() optim: string;
  @Input()  optimizationData: any = null;
  @Output() pfOptim: EventEmitter<any> = new EventEmitter<any>();
  @Output() calculateClicked = new EventEmitter<any>();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  showStocks = true;
  showUnconstrainted = false;
  showEfWeights = false;
  service: PortfolioService;
  dataSource = new MatTableDataSource<any>();
  columns: any[] = ['company_name', 'weight'];


  constructor(injector: Injector) {
    super(injector);
    this.chartConstructor = 'chart';
    this.service = injector.get(PortfolioService)
  }

  currentIndex = 0;
  customPointAdded = false;
  currentReturn: any = {}


  initChart() {

    const setCurrentIndex = (idx, vol, ret) => {
      if (!this.customPointAdded) {
        this.currentIndex = idx;
        this.currentReturn = {
          vol: vol.toFixed(1),
          ret: ret.toFixed(1)
        }
        if(idx < 101){
          this.optimWeights = this.optimWeights.map(o => {
            o.weight = o.id_w[this.currentIndex].toFixed(1);
            return o;
          })
        }else{

        }

         this.dataSource.data = this.optimWeights.filter((o)=>o.weight > 0).sort((a, b) => {return parseFloat(b.weight) - parseFloat(a.weight)});

         this.series[0].data.map(d => {
            if (d.return_index === idx || idx == 101){
              d.weight = this.dataSource.data;
            }
            return d;
          });
         
      }
    }

    const handleLineClick = (point?) => {
      if (!this.customPointAdded) {
        if (point && point.series.name == 'Optim Efficient Frontier') {
          // Add custom point to the list
          this.chartInstance.series[2].addPoint({
            name: 'Clicked Point',
            x: point.x,
            y: point.y,
            color: '#40C6BA',
          })
          this.customPointAdded = true;
        }
      } else {
        // Remove last point
        this.chartInstance.series[2].data[this.chartInstance.series[2].data.length-1].remove()
        this.customPointAdded = false;
      }
    };

    const findDataBehindOptim = (point?) => {
      
      this.series[0].data.map(d => {
       // if (d.x === point.x && d.y === point.y) {
          setCurrentIndex(101, d.x, d.y);
      //  }
      });
    };

    this.chartOptions = {
      chart: {
        height: '630px',
        events: {
          click: function() {
            handleLineClick();
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Efficient Frontier',
         style: {
            textTransform: 'none'
          }
      },
      xAxis: {
        title: {
          text: 'Expected Volatility',
          style: {
            textTransform: 'capitalize'
          }
        },
        labels: {
          format: '{value}%'
        },
        minPadding: 0.2,
        maxPadding: 0.2,
      },
      yAxis: {
        minorGridLineWidth: 0,
        title: {
          text: 'Expected Return',
          style: {
            textTransform: 'capitalize'
          }
        },
        labels: {
          format: '{value}%'
        }
      },
      legend: {
        enabled: false,
      },
      colors: ['#424CCD', '#0c0c68',  '#40C6BA'],
      
      series: this.series,
      tooltip: {
        enabled: true,
        formatter: function () {
          let data: any = this.point;
          var res = ''
          if(data.options.weight)
            for(let i = 0; i< data.options.weight.length; i ++){
                res += "<br /><p style='color=#40c6ba'>" + data.options.weight[i].company_name + ": " + data.options.weight[i].weight + "%</p>";
            }         
          return (data.company || data.name || 'Efficient Frontier') + '<br/> Expected volatility: ' + data.x + '%<br/>Expected return: ' + data.y + '%' + '<br/>Sharpe ratio: ' + (data.options.sharpeRatio/100).toFixed(2)  + res;
        }
      },
      plotOptions: {
        scatter: {
          dataLabels: {
            enabled: true,
              formatter: function() {
                         let name: any = this.point; 
                        var color = '#424CCD';
                         if(name.name == "Baseline Portfolio")
                           color = '#40c6ba';
                         if(name.name == "Max Sharpe" 
                          || name.name == 'Target Risk' 
                          || name.name == 'Max Return'
                          || name.name == 'Target Return'
                          || name.name == 'Minimum Risk'
                          ){
                          color = "#0c0c68";
                        }

                         return '<span style="color: ' + color + '; font-size: 12px;   ">' +  name.name + '</span>';   
                     },
            allowOverlap: true,

            position: 'right',
        //   format: "{point.name}",
          }
        },
        series: {
          point: {
            events: {
              mouseOver: function () {
                // Purple line hover, set weight index
                if (this.series.name == 'Optim Efficient Frontier') {
                  const point: any = this;
                  setCurrentIndex(point.return_index, point.x, point.y)
                }
                if (this.series.name == 'Optim') {
                  findDataBehindOptim(this);
                }
              },
              click: function(event) {
                handleLineClick(event.point)
              }
            }
          }
        }
      },
    };
    if (this.fullHeight) {
      this.chartOptions.chart.height = '88%';
    }
    setTimeout(() => this.loaded = true, 10);

  }

  onPfChange(value: string){
    this.service.changePfOptim(value);
  }
  onStockToggleChange() {
    if (this.showStocks) {
      this.chartInstance.series[3].show();
    } else {
      this.chartInstance.series[3].hide();
    }
  }

  onEfWeightsToggleChange() {
    this.showEfWeights = !this.showEfWeights;
  }


  onOptimizeClick(){

    
  }
  onConstraintToggleChange() {
    if (this.showUnconstrainted) {
      this.chartInstance.series[2].show();
    } else {
      this.chartInstance.series[2].hide();
    }
  }

  calculate(hasData = true) {
    this.calculateClicked.emit({
      return: hasData ? this.optimizationData.optim_parameters.efficient_target_return : null,
      risk: hasData ? this.optimizationData.optim_parameters.efficient_target_risk : null,
      hasData: hasData
    })
  }
}
