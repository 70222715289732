import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilService} from "../../shared/services/util.service";
import {ViewsService} from "../../shared/services/views.service";
import * as buffer from "buffer";

@Component({
  selector: 'app-views-setter',
  templateUrl: './views-setter.component.html',
  styleUrls: ['./views-setter.component.scss']
})
export class ViewsSetterComponent implements OnInit {

  @Input() sectors: any[] = []
  @Input() countries: any[] = []
  @Input() assets: any[] = []
  @Input() portfolio: any;
  @Input() views: any[] = []
  @Input() isSummary: boolean = false
  @Output() optimize = new EventEmitter();

  activeView: any = {};
  list: any[] = [];
  selectedViews = [];
  showOptimize = false;

  types = [
    { id: 'asset', name: 'Asset' },
    { id: 'sector', name: 'Sector' },
    { id: 'country', name: 'Country' }
  ];

  viewTypes = [
    { id: 'relative', name: 'Relative'},
    { id: 'absolute', name: 'Absolute'},
    { id: 'relative_market_equilibrium', name: 'Relative Market Equilibrium'},
  ];

  takes = [
    { id: 'outperform', name: 'Outperform'},
    { id: 'underperform', name: 'Underperform'},
  ];

  constructor(
    private util: UtilService,
    private service: ViewsService
  ) { }

  ngOnInit(): void {
  }

  onSelect(v) {
    if (this.util.isItemInArray(v, this.selectedViews)) {
      this.selectedViews = this.util.findAndReplaceItemInArray(v, this.selectedViews, true);
    } else {
      this.selectedViews.push(v);
    }
  }

  selectAll() {
    this.views.forEach(v => {
      this.onSelect(v);
      v.selected = !v.selected;
    })
  }

  deleteBulk() {
    this.selectedViews.map(v => {
      this.deleteView(v);
    });
    this.selectedViews = [];
  }


  onTypeChange() {
    this.list = [];
    switch (this.activeView.type) {
      case 'asset':
        this.list = this.assets;
        break;
      case 'sector':
        this.list = this.sectors;
        break;
      case 'country':
        this.list = this.countries;
        break;
      default:
        this.list = [];
    }
  }

  onViewTypeChange() {
    if (this.activeView.view_type === 'absolute') {
      delete this.activeView.take;
      delete this.activeView.items2;
    }
  }

  initActiveView()
  {
    return {
      type: this.types[0],
      portfolio_id: this.portfolio.id,
    }
  }

  onAddView() {
    this.saveView()
    this.activeView = this.initActiveView();
    this.list = this.assets;
    this.showOptimize = true;
  }

  saveView() {
    if (this.activeView.type && this.activeView.items) {
      this.service.create(this.activeView).subscribe(resp => {
        if (resp.error) {
          return this.service.notificationService.open(resp.error);
        }
        if (this.activeView.id) {
          this.views = this.util.findAndReplaceItemInArray(resp, this.views);
        } else {
          this.views.push(resp);
        }
        this.showOptimize = true;
        this.discardView();
      });
    }
  }

  editView(c) {
    this.activeView = {...c};
    this.activeView.type = c.type.id;
    this.onTypeChange();
    this.activeView.items = c.items.map(i => +i.id);
    this.activeView.items2 = c.items2.map(i => +i.id);
    this.activeView.portfolio_id = this.portfolio.id;
  }

  deleteView(v) {
    this.service.delete(v).subscribe(resp => {
      this.views = this.views.filter(viw => viw.id !== v.id);
    });
  }

  discardView() {
    this.activeView = {};
  }

}
